import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  fetchDispatcher,
  resetDispatcher,
  saveDispatcher,
  useSelector as useEditSelector,
} from "../../../../../redux/store/folders/slices/edit";
import { Form } from "./Form";
import { AEButton } from "../../../../../_ae/components/buttons";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Edit = ({ history, match: { params: { id } } }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isLoading, data, error } = useEditSelector();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    dispatch(
      id ?
      fetchDispatcher(id) :
      resetDispatcher()
    );
  }, [id, dispatch]);

  useEffect(() => {}, [data]);

  const saveBtnRef = useRef();
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const { current } = saveBtnRef;
      current.click();
    }
  };

  const initialValues = id ? data : {
    reference: '',
    clientName: '',
    phone: '',
    matricule: '',
    marque: '',
    notes: '',
    city: '',
    assignTo: '',
    attachments: [],
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <Card>
      <ToastContainer />
      {(isLoading || isSubmitting) && <ModalProgressBar />}
      <CardHeader className={"d-flex justify-content-end align-items-center"}>
        <AEButton variant={"light"} onClick={history.goBack}>
          <i className="fa fa-arrow-left" />
          {formatMessage({ id: "BACK" })}
        </AEButton>
        {showButton ? (
          <AEButton
            className="ml-2"
            onClick={() => {
              setShowButton(false);
              saveBtnRefClick();
            }}
            disabled={isSubmitting}
          >
            {formatMessage({ id: "SAVE" })}
          </AEButton>
        ) : (
          <Spinner animation="border" variant="primary" className="ml-2" />
        )}
      </CardHeader>
      <CardBody className={"pt-10"}>
        <Formik
          enableReinitialize
          initialErrors={error}
          validationSchema={Yup.object().shape({
            id: Yup.number().nullable(),
            reference: Yup.string().required(),
            phone: Yup.string()
              .matches(/^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/, "(+212)xxxxxxxxxx")
              .required(),
            clientName: Yup.string().required(),
            matricule: Yup.string().required(),
            marque: Yup.string().required(),
            notes: Yup.string(),
            city: Yup.object().required(),
            assignTo: Yup.object().required(),
          })}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setIsSubmitting(true); // Activer l'indicateur de soumission
            const payload = {
              ...values,
              city: values.city.id,
              assignTo: values.assignTo.id,
              paramId: id !== undefined ? id : undefined,
            };
          
            // Clear any existing toasts
            toast.dismiss();
          
            dispatch(saveDispatcher(payload, { attachments: values.attachments }))
              .then((response) => {

              })
              .catch(() => {
               
              })
              .finally(() => {
                setIsSubmitting(false); // Désactiver l'indicateur après soumission
                setShowButton(true);
                setSubmitting(false);
              });
            console.log(" attachments :", values.attachments); // Pour débogage
          }}
          
          
          render={(formik) => <Form {...formik} btnRef={saveBtnRef} />}
        />
      </CardBody>
    </Card>
  );
};
