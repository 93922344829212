import axios from "axios";
import { axiosFormDataConfigs, getFormData } from "../../../_ae/helpers/AxiosHelpers";
import { toAbsoluteApiUrl } from "../../../_metronic/_helpers";
import { MODULES } from "../../../_ae/helpers/RoutingHelpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.FOLDERS}`);

const normalize = (_entity) => {
  const { id, reference, phone, notes, clientName, matricule, marque, city, assignTo, folderId } = _entity;
  return { id, reference, phone, notes, city, assignTo, clientName, matricule, marque, folderId };
};

const normalizeLog = (_entity) => {
  const { id, assignTo, folderId, city, status, reference, phone, matricule, marque, notes, clientName } = _entity;
  let assignToId = undefined;
  if (assignTo) {
    assignToId = assignTo.id;
  }
  return { id, assignTo, folder: { id: folderId }, city, status, reference, phone, matricule, marque, notes, clientName };
};

const normalizeUpdateLog = (_entity) => {
  const { id, assignTo, folderId, city, status, reference, phone, matricule, marque, notes, clientName, paramId } = _entity;
  let assignToId = undefined;
  if (assignTo) {
    assignToId = assignTo.id;
  }
  return { id, assignTo, folder: { id: folderId }, city, status, reference, phone, matricule, marque, notes, clientName, paramId };
};

export const all = (metadata) => axios.get(API_URI, { params: { meta: metadata } });
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, { params: { meta: metadata } });
export const destroy = (id) => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = (entity, id) => axios.post(`/folder_logs/${id}?_method=PUT`, getFormData(normalizeLog(entity)), axiosFormDataConfigs);
export const folderUpdate = async (entity, paramId,files) => {
  const url = toAbsoluteApiUrl(`/folderUpdate/${paramId}`);
  return await axios.post(url, getFormData(normalizeLog(entity), files), axiosFormDataConfigs);
}; export const updateFolderLog = (folderId, entity) => axios.post(toAbsoluteApiUrl(`/folder-update/${folderId}`), getFormData(normalizeLog(entity)));
export const createLog = (entity) => axios.post(toAbsoluteApiUrl('/folder_logs'), normalizeUpdateLog(entity));
export const getFolderLogs = (folderId) => axios.get(toAbsoluteApiUrl(`/folder_logs/${folderId}`));
