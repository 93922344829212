import { createSlice } from "@reduxjs/toolkit";
import { shallowEqual, useSelector } from "react-redux";
import { HTTP_NOT_ACCEPTABLE } from "../../_ae/helpers/AxiosHelpers";
import { getIntlMessage } from "../../_metronic/i18n";

export const getSlice = ({ name, metadata, data }) => {
  const isArray = data instanceof Array;

  const initialState = {
    initialData: data,
    data,
    isLoading: false,
    error: null,
  };

  switch (true) {
    case isArray:
      if (metadata) {
        initialState.metadata = metadata;
      }
      break;
    // case isObject:
  }

  return createSlice({
    name,
    initialState,
    reducers: {
      startCall: (state) => {
        state.error = null;
        state.isLoading = true;
      },
      endCall: (state) => {
        state.isLoading = false;
      },
      catchError: (state, { payload }) => {
        const { response } = payload;
        if (response) {
          const { status, data } = response;
          switch (status) {
            case HTTP_NOT_ACCEPTABLE:
              state.error = {};
              data._data.forEach(({ message: id, propertyPath: path }) => {
                state.error[path] = getIntlMessage({ id }, { path });
              });
              break;
            default:
              state.error = data;
              break;
          }
        } else {
          state.error = payload.message || 'An error occurred';
        }
      },
      reset: (state) => {
        state.data = data;
      },
      fetched: (state, action) => {
        const { _data, _metadata } = action.payload.data;

        state.error = {};
        state.data = _data;

        switch (true) {
          case isArray:
            if (metadata && _metadata) {
              const { total, pages } = _metadata.pagination;

              state.metadata.pagination.total = total;
              state.metadata.pagination.pages = pages;
            }
            break;
        }
      },
    },
  });
};

export const useCustomSelector = (sliceName) => {
  return useSelector((s) => s[sliceName], shallowEqual);
};
