import React from "react";
import { toAbsoluteApiUrl, toAbsoluteUploadUrl } from "../../_metronic/_helpers";
import { AESVG } from "./svg";
import axios from "axios";
import { Button } from "react-bootstrap"; // Assurez-vous d'importer correctement

export const AEMediaDownload = ({ target = '', id, fileName, fetchData }) => {

    const i = fileName.lastIndexOf('.');
    const name = fileName.substr(0, i);
    const ext = fileName.substr(i + 1);

    const iconMap = {
        'image': ['png', 'jpg', 'jpeg', 'svg'],
        'document': ['txt'],
        'doc': ['doc', 'docx'],
        'pdf': ['pdf'],
        'css': ['css'],
        'csv': ['csv'],
        'html': ['html'],
        'javascript': ['javascript'],
        'mp4': ['mp4'],
        'xml': ['xml'],
        'zip': ['zip', 'rar', '7zip'],
    }

    const getIconName = (ext) => {
        let iconName = 'unknown';
        Object.keys(iconMap).forEach((key) => {
            if (iconMap[key].includes(ext)) return iconName = key;
        })

        return iconName;
    }

    const handleDelete = () => {
        axios.delete(toAbsoluteApiUrl(`/${target}/${id}`))
            .then(() => {
                // Handle successful delete
                console.log('Deleted successfully');
                fetchData(); // Fetch data after delete
            })
            .catch(error => {
                console.error('Error deleting the file', error);
            });
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
            {
                ['png', 'jpg', 'jpeg', 'svg'].includes(ext) &&
                <img src={toAbsoluteUploadUrl(`/${target}/${fileName}`)} alt=""
                     style={{
                         maxWidth: 15 + 'em',
                         padding: "23px 23px 0 23px"
                     }}
                />
            }
            {
                ext === 'mp4' &&
                <video controls
                       style={{
                           maxWidth: 17 + 'em',
                           padding: "23px 23px 0 23px"
                       }}
                >
                    <source src={toAbsoluteUploadUrl(`/${target}/${fileName}`)} type="video/mp4"/>
                </video>
            }
            <Button 
                variant="light" 
                onClick={handleDelete} 
                style={{
                    position: 'absolute', 
                    top: '12px', 
                    right: '12px', 
                    padding: '0.25em 0.5em', 
                    fontSize: '1.24em', 
                    lineHeight: '1', 
                    borderRadius: '50%', 
                    backgroundColor: 'gray', 
                    color: 'white',
                    border: 'none'
                }}
            >
                ×
            </Button>
            <figcaption>
                <a
                    href={toAbsoluteUploadUrl(`/${target}/${fileName}`)}
                    className={"pl-5"}
                    download
                    target='_blank'
                    onClick={e => {
                        e.preventDefault();
                        axios.get(
                            toAbsoluteApiUrl(`/${target}/${id}/download`), { responseType: 'blob' })
                            .then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', fileName);
                                document.body.appendChild(link);
                                link.click();
                            });
                    }}
                >
                    Télécharger <AESVG path={"/Files/Download.svg"} />
                </a>
            </figcaption>
        </div>
    )
}
