import { getSlice, useCustomSelector } from "../../helpers";
import * as API from "../api";
import { MODULES } from "../../../../_ae/helpers/RoutingHelpers";

const { actions, name, reducer } = getSlice({
    name: `${MODULES.ROLES}.edit`,
    data: {
        name: '',
        roleId: 'ROLE_',
        roleRoutes: [
            {
                route: undefined //FIXME Conflict YupJs & formik
            }
        ]
    },
});

const { startCall, endCall, fetched, catchError, reset } = actions;

const resetDispatcher = () => dispatch => {
    dispatch(reset());
};

const fetchDispatcher = (id, metadata) => dispatch => {
    console.log("idssrrrr : ", id);
    dispatch(startCall());

    return API
        .find(id, metadata)
        .then(response => {
            dispatch(fetched(response));
            console.log("response : ", response.data);
        })
        .catch(response => {
            dispatch(catchError(response));
        })
        .then(() => {
            dispatch(endCall());
        });
};

// New function to fetch and display a role by its ID
const displayRoleDispatcher = (id) => dispatch => {
    dispatch(startCall());

    return API
        .find(id)
        .then(response => {
            // Dispatch the fetched action with the API response
            dispatch(fetched(response));
        })
        .catch(error => {
            // Dispatch the catchError action if the API call fails
            dispatch(catchError(error));
        })
        .finally(() => {
            // Dispatch the endCall action after the API interaction is complete
            dispatch(endCall());
        });
};

const saveDispatcher = (entity, files) => dispatch => {
    dispatch(startCall());

    const ApiCall = entity.id ? API.update : API.find;

    return ApiCall(entity, files)
        .then(response => {
            dispatch(fetched(response));
        })
        .catch(response => {
            dispatch(catchError(response));
        })
        .then(() => {
            dispatch(endCall());
        });
};

const useSelector = () => useCustomSelector(name);

export {
    actions,
    name,
    reducer,
    fetchDispatcher,
    resetDispatcher,
    saveDispatcher,
    displayRoleDispatcher,
    useSelector,
};
