import {getSlice, useCustomSelector} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";


const {actions, name, reducer} = getSlice({
  name: `${MODULES.USERS}.foldersEdit`,
  data: [],
  metadata: new AEMetadata(
    {},
    {field: 'reference',
     firstName: 'first_name',
     lastName: 'last_name',
    },
    {},
    {"*": []}
  )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
  dispatch(startCall());

  return API
    .all(metadata)
    .then(response => {
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


