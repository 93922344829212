import { getSlice, useCustomSelector } from "../../helpers";
import * as API from "../api";
import { MODULES } from "../../../../_ae/helpers/RoutingHelpers";
import { ToastContainer, toast } from "react-toastify";



const { actions, name, reducer } = getSlice({
  name: `${MODULES.FOLDERS}.edit`,
  data: {
    reference: '',
    clientName: '',
    phone: '',
    matricule: '',
    marque: '',
    notes: '',
    city: '',
    assignTo: '',
  },
});

// const {id} = useParams();



const { startCall, endCall, fetched, catchError, reset } = actions;

const resetDispatcher = () => (dispatch) => {
  dispatch(reset());
};

const fetchDispatcher = (id, metadata) => (dispatch) => {
  dispatch(startCall());

  return API
    .find(id, metadata)
    .then((response) => {
      dispatch(fetched(response));
    })
    .catch((error) => {
      dispatch(catchError(error));
    })
    .finally(() => {
      dispatch(endCall());
    });
};



const saveDispatcher = (entity, files) => (dispatch) => {
  dispatch(startCall());

  // const ApiCall = API.create;
  // const ApiCallUpdate = API.folderUpdate(entity, entity.paramId);

  const payload = { ...entity };

  // if (!entity.paramId) {
  //   return ApiCall(payload, files)
  //     .then((response) => {
  //       dispatch(fetched(response.data));
  //       if (payload.assignTo) {
  //         return API.createLog({ folderId: response.data.id, assignTo: payload.assignTo });
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(catchError(error));
  //     })
  //     .finally(() => {
  //       dispatch(endCall());
  //     });
  // } else {
  //   return ApiCallUpdate(payload, files)
  //     .then((response) => {
  //       dispatch(fetched(response.data));
  //     })
  //     .catch((error) => {
  //       dispatch(catchError(error));
  //     })
  //     .finally(() => {
  //       dispatch(endCall());
  //     });
  // }

  // console.log(entity);
  // console.log(entity.paramId);
  // console.log(entity.paramId !== undefined);

  if (entity.paramId !== undefined) {
    return API.folderUpdate(payload, entity.paramId , files)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Enregistrer");
      }
        dispatch(fetched(response.data));
      })
      .catch((error) => {
        dispatch(catchError(error));
      })
      .finally(() => {
        dispatch(endCall())
      });
  } else {
    return API.create(payload, files)
      .then((response) => {
        dispatch(fetched(response.data));
        if (payload.assignTo) {
          return API.createLog({ folderId: response.data.id, assignTo: payload.assignTo });
        }
      })
      .catch((error) => {
        dispatch(catchError(error));
      })
      .finally(() => {
        dispatch(endCall());
      });
  }


};

const useSelector = () => useCustomSelector(name);

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  saveDispatcher,
  useSelector,
};
