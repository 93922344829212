import React from "react";
import {AELabel} from "../../../../_ae/components/AELabel";
import {useIntl} from "react-intl";

export const STATUSES = {
  // PENDING : {
  //   id: 'PENDING',
  //   variant: 'light',
  //   roles: []
  // },
  UNREACHABLE: {
    id: 'UNREACHABLE',
    variant: 'unreachable',
    roles: []
  },
  
  ONGOING: {
    id: 'ONGOING',
    variant: 'ongoing',
    roles: []
  },
  VALIDATED: {
    id: 'VALIDATED',
    variant: 'validated',
    roles: []
  },
  CANCELLED: {
    id: 'CANCELLED',
    variant: 'cancelled',
    roles: []
  },
  CLOSED: {
    id: 'CLOSED',
    variant: 'primary',
    roles: []
  },
  
}
export const getStatusKeys  = () => Object.keys(STATUSES).map(key=>STATUSES[key].id)
export const getStatus  = key => STATUSES[key]

export const Status = ({statusKey})=>{
  const { formatMessage } = useIntl()
  const status = getStatus(statusKey);
  if (! status) return null;
  return (
    <AELabel
      variant={status.variant}
      title={formatMessage({id: status.id})}
    />
  )
}