import {getSlice, useCustomSelector} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.ROLES}.list`,
  data: [],
  metadata: new AEMetadata(
    {},
    {field: 'name'},
    {},
    {"*":[], "roleRoutes": {"*":[],  "role": {"*":[]}}},
  )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = (metadata) => dispatch => {
  dispatch(startCall());

  const fetchFunction = API.all(metadata);

  return fetchFunction
    .then(response => {
      dispatch(fetched(response));
      return response;
    })
    .catch(response => {
      dispatch(catchError(response));
      return response;
    }).then(response => {
      dispatch(endCall(response));
    });
};


const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


