import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Card,
    CardBody, CardFooter,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    saveFolderUpdate, useFoldersUpdatesEditState
} from "../../../../../redux/store/folders";
import { Form } from "./Form";
import { AEButton } from "../../../../../_ae/components/buttons";
import { getFolderLogs } from "../../../../../redux/store/folders/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useRolesListState } from "../../../../../redux/store/roles";

export const Edit = ({ history, match: { params: { id } } }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { isLoading, data, error } = useFoldersUpdatesEditState();
    const { dataa, IsLoading, metadata } = useRolesListState()
    const [folderId, setFolderId] = useState(null);

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const { current } = saveBtnRef;
            current.click();
        }
    };

    useEffect(() => {
        // Fetch folder logs when component mounts
        getFolderLogs(id)
            .then(response => {
                const folderLogId = response.data[0]?.id;
                setFolderId(folderLogId);
                // console.log('DATA:', response.data);
            })
            .catch(error => {
                console.error('Error fetching folder logs:', error);
            });
    }, [id]);

    return (
        <Card>
            {isLoading && <ModalProgressBar />}
            <CardFooter className={'d-flex justify-content-end align-items-center'}>
                <AEButton
                    variant={'light'}
                    onClick={history.goBack}
                >
                    <i className="fa fa-arrow-left" />
                    {formatMessage({ id: 'BACK' })}
                </AEButton>
                <AEButton
                    className="ml-2"
                    onClick={saveBtnRefClick}
                >
                    {formatMessage({ id: 'SAVE' })}
                </AEButton>
            </CardFooter>

            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={error}
                    validationSchema={
                        Yup.object().shape({
                            id: Yup.number().nullable(),
                            status: Yup.string().required(),
                            cabinetNote: Yup.string(),
                            attachments: Yup.string(),
                            assignTo: Yup.object().nullable(),
                            city: Yup.object().required(),
                        })
                    }
                    initialValues={{ ...data, folderId: folderId }}
                    onSubmit={async ({ attachments, ...values }) => {
                        console.log("folderId :", id);
                        // console.log("attachments :", attachments);

                        const datax = { ...values }

                        // console.log("datax :", datax);
                        // console.log(datax)
                        const formData = new FormData();

                        Object.keys(datax).forEach((key) => {
                            // Check if the value is an object (e.g., city or assignTo) and convert it to JSON
                            if (typeof datax[key] === 'object' && datax[key] !== null) {
                                formData.append(key, JSON.stringify(datax[key]));
                            } else {
                                formData.append(key, datax[key]);
                            }
                        });
                    
                        // Append files from attachments to FormData
                        if (Array.isArray(attachments)) {
                            attachments.forEach((file, index) => {
                                formData.append(`attachments[${index}]`, file);
                            });
                        }
                    
                        // console.log('FormData:', formData);


                        // Create FormData for file upload
                        // Object.keys(datax).forEach((key) => {
                        //     formData.append(key, datax[key]);
                        // });

                        console.log(formData);

                        // Check and append attachments as files to FormData
                        // if (attachments && Array.isArray(attachments)) {
                        //     attachments.forEach((file, index) => {
                        //         // Ensure file is a File object
                        //         if (file instanceof File) {
                        //             formData.append(`attachments[${index}]`, file);
                        //         }
                        //     });
                        // }

                        // Dispatch action with FormData if necessary (optional step)
                        // dispatch(saveFolderUpdate({ ...data, folderId, attachments }));

                        try {
                            const res = await axios.post(
                                `https://api.abexpertise.ma/create-tache/${id}`, formData

                            );

                            // if (res.status === 200) {
                            //     toast.success("Enregistrer");
                            // }
                            // console.log(formData);

                        } catch (error) {
                            console.error("Error updating folder:", error);
                            toast.error("Une erreur s'est produite");
                        }
                    }}


                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />)}
                />
            </CardBody>

            <ToastContainer />
        </Card>
    );
}
