import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody, CardFooter,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  fetchUserForEdit, resetUserForEdit,
  saveUser,
  useUsersEditState
} from "../../../../../redux/store/users";
import { Form } from "./Form";
import { AEButton } from "../../../../../_ae/components/buttons";
import { fetchRolesUsersEdit } from "../../../../../redux/store/roles";
import { YupAuthPassword, YupAuthPasswordConfirm } from "../../../../../_ae/yupjs/Schema";

//todo server validation errors
export const Edit = ({ history, match: { params: { id } } }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isLoading, data, error } = useUsersEditState();

  useEffect(() => {
    dispatch(
      id ?
        fetchUserForEdit(id) :
        resetUserForEdit()
    );

    dispatch(fetchRolesUsersEdit());
  }, [id, dispatch]);


  useEffect(() => {
    console.log("State Data: ", data); // Log the state data after fetch
  }, [data]);


  const saveBtnRef = useRef();
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const { current } = saveBtnRef;
      current.click();
    }
  };

  return (
    <Card>
      {isLoading && <ModalProgressBar />}
      <CardBody className={'pt-10'}>
        <Formik
          enableReinitialize
          initialErrors={error}
          validationSchema={
            Yup.object().shape({
              id: Yup.number().nullable(),
              firstName: Yup.string().required(),
              lastName: Yup.string(),
              phone: Yup.string()
                .matches(/^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/, "(+212)xxxxxxxxxx")
                .required(),
              email: Yup.string().email(),
              password: Yup.string().when('id', {
                is: (val) => !val,
                then: YupAuthPassword()
              }),
              _confirm: Yup.string().when('id', {
                is: (val) => !val,
                then: YupAuthPasswordConfirm()
              }),
              role: Yup.object().required(),
              city: Yup.object(),
            })
          }
          initialValues={data}
          onSubmit={values => {
            // Log the form values to the console
            console.log('Form values:', values);
            dispatch(saveUser(values));
          }}
          render={formik => (
            <Form
              {...formik}
              btnRef={saveBtnRef}
            />)}
        />
      </CardBody>
      <CardFooter className={'d-flex justify-content-end'}>
        <AEButton
          variant={'light'}
          onClick={history.goBack}
        >
          <i className="fa fa-arrow-left" />
          {formatMessage({ id: 'BACK' })}
        </AEButton>
        <AEButton
          className="ml-2"
          onClick={saveBtnRefClick}
        >
          {formatMessage({ id: 'SAVE' })}
        </AEButton>
      </CardFooter>
    </Card>
  );
}
