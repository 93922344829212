import {getSlice, useCustomSelector} from "../../helpers";
import * as API from "../api";
import {createLog} from "../api";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";
import axios from "axios";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.FOLDERS}.editUpdate`,
  data: {
    id:'',
    cabinetNote: '',
    city: undefined,
    assignTo: undefined,
    attachments: undefined
  },
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = (id, metadata) => dispatch => {
  dispatch(startCall());
  return API
    .find(id, metadata)
    .then(response => {
      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const saveDispatcher = (entity, files) => dispatch => {
  dispatch(startCall());

  const formData = new FormData();

  Object.keys(entity).forEach(key => {
    formData.append(key, entity[key]);
  });

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      formData.append('attachments', file);
    });
  }

  const folderId = entity.folderId;

  const xdata = {
    cc:'value',
    ccc:'value'
  }

//  const res =  axios.put(`https://api.abexpertise.ma/folderupdatee`, xdata, {
//     // headers: {
//     //   'Accept': 'application/json',
//     // },
//   })

//   console.log(res);
};

const useSelector = () => useCustomSelector(name)


export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  saveDispatcher,
  useSelector
}
