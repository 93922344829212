import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { AEAutocompleteField, AEField, AEFileField } from "../../../../../_metronic/_partials/controls/forms/AEField";
import { fetchUsersForFoldersEdit, useUsersForFoldersEditState } from "../../../../../redux/store/users";
import { fetchForCitiesSelect, useCitiesForSelectState } from "../../../../../redux/store/cities";

export const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  btnRef,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data: users } = useUsersForFoldersEditState();
  const { data: cities } = useCitiesForSelectState();

  useEffect(() => {
    dispatch(fetchUsersForFoldersEdit());
    dispatch(fetchForCitiesSelect());
  }, [dispatch]);

  useEffect(() => {
  }, [users, values]);
  

  const getOptionLabel = (user) => {
    if (!user) return '';
    return `${user.firstName} ${user.lastName}`;
  };

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-6"}>
          <AEField
            name="reference"
            label={formatMessage({ id: 'REFERENCE' })}
            value={values.reference}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.reference && !!errors.reference}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg-6"}>
          <AEField
            name="clientName"
            label={formatMessage({ id: 'CLIENT_NAME' })}
            value={values.clientName}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.clientName && !!errors.clientName}
          />
        </div>

        <div className={"col-lg-6"}>
          <AEField
            name="phone"
            label={formatMessage({ id: 'PHONE' })}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.phone && !!errors.phone}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg-6"}>
          <AEField
            name="matricule"
            label={formatMessage({ id: 'MATRICULE' })}
            value={values.matricule}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.matricule && !!errors.matricule}
          />
        </div>

        <div className={"col-lg-6"}>
          <AEField
            name="marque"
            label={formatMessage({ id: 'MARQUE' })}
            value={values.marque}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.marque && !!errors.marque}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-6"}>
          <AEAutocompleteField
            name="city"
            label={formatMessage({ id: 'CITY' })}
            options={cities}
            getOptionLabel={city => city.name}
            value={values.city}
            onChange={(e, value) => setFieldValue('city', value)}
            onBlur={handleBlur}
            isInvalid={touched.city && !!errors.city}
          />
        </div>

        <div className={"col-lg-6"}>
          <AEAutocompleteField
            name="assignTo"
            label={formatMessage({ id: 'ASSIGN_TO' })}
            options={users.filter(user => user.role.name === "ROLE_AGENT")}
            getOptionLabel={getOptionLabel}
            value={values.assignTo}
            onChange={(e, value) => setFieldValue('assignTo', value)}
            onBlur={handleBlur}
            isInvalid={touched.assignTo && !!errors.assignTo}
          />
        </div>
        
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="notes"
            label={formatMessage({ id: 'NOTES' })}
            type={'textarea'}
            rows={8}
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.notes && !!errors.notes}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEFileField
            name="attachments"
            label={formatMessage({ id: 'ATTACHMENTS' })}
            multiple
            value={values.attachments}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.attachments && !!errors.attachments}
          />
        </div>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
};
