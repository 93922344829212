import React from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { AESVG, SVG_ICON } from "../../../../_ae/components/svg";
import { useIntl } from "react-intl";
import { AELink } from "../../../..//_ae/components/AELink";
import { Name } from "./Name";
import clsx from "clsx";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import { Dropdown } from "react-bootstrap";
import { AERole } from "../../../..//_ae/components/AERole";
import { AETruncate } from "../../../..//_ae/helpers/UIHelper";
import { AEMoment } from "../../../..//_ae/components/moment";
import { getStatus, Status } from "./Status";
import { UserEntityCard } from "../../Users";
import { useRoutesForAppState } from "../../../../redux/store/routes";
import { useSelector } from "react-redux";
import "./folderStyle.css";
import { MODULES } from "../../../../_ae/helpers/RoutingHelpers"; // Ensure the correct path

export const EntityCard = ({
  entity,
  size = "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
  detailsAction,
  addUpdate,
  showCheckbox,
  onSelect,
  selected,
}) => {
  const { formatMessage } = useIntl();
  const { data: routes, routesLoading } = useRoutesForAppState();
  const authUser = useSelector(state => state.auth.user);

  const classes = {
    xs: {
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm: {
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md: {
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg: {
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  };

  const sizeClasses = classes[size];
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isXs = size === 'xs';

  const mostRecentLog = entity.logs?.reduce((latest, log) => {
    return new Date(log.createdAt) > new Date(latest.createdAt) ? log : latest;
  }, entity.logs[0]);

  const actions = [
    { id: 'FOLDERS.UPDATES.NEW', show: addUpdate },
    { id: 'FOLDERS.EDIT', show: editAction },
    { id: 'FOLDERS.DELETE', show: deleteAction, path: `/${MODULES.FOLDERS}/${entity.id}/delete` }, // Delete action with path
    { id: 'FOLDERS.DETAIL', show: detailsAction },
  ].filter(action => action.show);
  return (
    <>
      <Card className={`${className} cart-border border-2 border-${getStatus(entity._status)?.variant}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className="d-flex align-items-center">
            {showCheckbox && (
              <input
                type="checkbox"
                checked={selected}
                onChange={() => onSelect(entity.id)}
                className="mr-2"
              />
            )}
            <div className="d-flex align-items-center flex-grow-1">
              <Name entity={entity} fontSize={sizeClasses.title} fontWeight={'bolder'} />
              <Status statusKey={entity._status} className="ml-2" />
              <div className="ml-auto">
                {actions.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle as={DropdownCustomToggler}>
                      <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {actions.map(action => {
                        const route = routes.find(r => r.id === action.id);
                        if (!route) return '';
                        const path = action.path || route.path.replace(':id', entity.id);

                        return (
                          <AERole key={route.id} roles={route.roles}>
                            <AELink to={path} className="dropdown-item">
                              <AESVG className="pr-2" path={route.svg} />
                              {formatMessage({ id: route.id })}
                            </AELink>
                          </AERole>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          <div className={clsx('flex-grow-1', !isXs && 'pt-1')}>
          {[
              {
                id: "AGENT : ",
                value: (
                  <span>
                    {mostRecentLog && mostRecentLog.assignTo && (
                      <>
                        <strong>{mostRecentLog.assignTo.firstName}</strong> {mostRecentLog.assignTo.lastName}
                      </>
                    )}
                  </span>
                )
              },
              {
                id: "CLIENT_NAME : ",
                value: <span> {entity.clientName} </span>
              },
              
              {
                id: "MATRICULE : ",
                value: <span> {entity.matricule} </span>
              },
              {
                id: "MARQUE : ",
                value: <span> {entity.marque} </span>
              },
              {
                id: "CREATION_DATE : ",
                value: <AEMoment date= {entity.createdAt} format="LLLL" />
              },
              {
                id: "UPDATED_AT : ",
                hidden: !entity.updatedAt,
                value: <AEMoment date= {entity.updatedAt} format="LLLL" />
              },
            ]
              .filter(op => !op.hidden)
              .map(op => (
                <div key={op.id} className="d-flex pb-1">
                  <span>
                    {!isXs && (
                      <span className="text-muted ml-2">{formatMessage({ id: op.id })}</span>
                    )}
                    {op.value}
                  </span>
                </div>
              ))}
          </div>
    
        </CardBody>
      </Card>
    </>
  );
};
